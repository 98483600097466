export default {
  // public
  COMING: '/coming-soon',
  TOS: '/term-of-service',
  PRIVACYPOLICY: '/privacy-policy',
  COMPONENTS: '/components',

  // SERVERS
  SERVERS: '/servers/*',

  HOME: '/',

  // BACKUPS
  BACKUPS: '/backups/',

  // MEMBERS
  MEMBERS: '/members/',

  // Notifications
  NOTIFICATIONS: '/notifications',
};
