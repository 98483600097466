const rest = {
  // auth
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/password:request',
  NEW_PASSWORD: '/auth/password:submit',
  EMAIL_AVAILABILITY: '/auth/email:available',
  PHONE_NUMBER_AVAILABILITY: '/auth/phone-number:available',
  SWITCH_TEAM: '/auth/switchteam',
  CHANGE_PASSWORD: (userId) => {
    return `/users/${userId}/password-change`;
  },

  // files
  UPLOAD_REQUEST: '/documents:upload',
  DOWNLOAD_REQUEST: '/documents:download',
};

export default rest;
