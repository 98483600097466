import { urlBuilder } from '../../utils/route';

const backup = {
  MAIN: '/backups/*',
  CREATE: '/create',
  BACKUP_ID: {
    MAIN: '/:backupId/*',
    SUMMARY: '/summary',
    SETTING: '/setting',
  },
};

export default backup;

export const backupURL = () => {
  return new Proxy(backup, urlBuilder('/backups'));
};

// const b = urlParams(serverURL().DETAIL.DASHBOARD, {
//   serverId: '1234',
// });
