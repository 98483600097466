export default {
  COOKIES: {
    USER_DATA: 'userData',
    TOKEN: 'token',
    DEVICE_TOKEN: 'deviceToken',
  },
  LOCAL_STORAGE: {
    USER_RULES: 'userRules',
    TEAM_ROLE: 'teamRole',
    CREATE_CLUSTER_DATABASE: 'createClusterDatabase',
  },
};
