import { urlBuilder } from '../../utils/route';

const service = {
  MAIN: '/services/*',
  // CLUSTER_DATABASE: '/cluster-databases',
  CLUSTER_DATABASE: {
    MAIN: '/cluster-databases/*',
    CREATE: '/create',
    CLUSTER_DATABASE_ID: {
      MAIN: '/:clusterDBId/*',
      NODE_INFO: '/node-information',
      DB_CREDENTIAL: '/db-credential',
      MONITORING_DASHBOARD: '/monitoring-dashboard',
      SETTING: '/setting',
      INSTALLING: '/installing',
    },
  },
  LOAD_BALANCER: '/load-balancer',
  LOGGER: '/logger',
};

export default service;

export const serviceURL = () => {
  return new Proxy(service, urlBuilder('/services'));
};
