import React, { lazy } from 'react';
import ADMIN_URL from '../configs/url/admin';
import BACKUP_URL from '../configs/url/backup';
import SERVER_URL from '../configs/url/server';
import AUTH_URL from '../configs/url/auth';
import ROUTE_URL from '../configs/url/general';
import SETTING_URL from '../configs/url/setting';
import ACCOUNT_URL from '../configs/url/account';
import SERVICE_URL from '../configs/url/service';

const Term = lazy(() => {
  return import('./tos');
});
const Policy = lazy(() => {
  return import('./privacyPolicy');
});
const Comp = lazy(() => {
  return import('./privacyPolicy');
});
const Auth = lazy(() => {
  return import('./auth');
});

const Members = lazy(() => {
  return import('./members');
});
const Admin = lazy(() => {
  return import('./admin');
});
const Servers = lazy(() => {
  return import('./servers');
});
const Backups = lazy(() => {
  return import('./backups');
});
const Account = lazy(() => {
  return import('./account');
});

const Notifications = lazy(() => {
  return import('./notifications');
});

const Settings = lazy(() => {
  return import('./settings');
});

const Services = lazy(() => {
  return import('./services');
});

const WithHeaderPage = lazy(() => {
  return import('./withHeader');
});

const general = [
  // public Routes
  {
    path: ROUTE_URL.TOS,
    component: <Term />,
    isPublic: true,
  },
  {
    path: ROUTE_URL.PRIVACYPOLICY,
    component: <Policy />,
    isPublic: true,
  },

  {
    path: AUTH_URL.MAIN,
    component: <Auth />,
    isPublic: true,
  },

  {
    path: '/*',
    component: <WithHeaderPage />,
    isPublic: false,
  },
];

const withHeader = [
  {
    path: ROUTE_URL.COMPONENTS,
    component: <Comp />,
    isMember: false,
  },
  {
    path: BACKUP_URL.MAIN,
    component: <Backups />,
    isMember: true,
  },
  {
    path: ROUTE_URL.MEMBERS,
    component: <Members />,
    isMember: true,
  },
  {
    path: ADMIN_URL.MAIN,
    component: <Admin />,
    isMember: false,
  },
  {
    path: SERVER_URL.MAIN,
    component: <Servers />,
    isMember: true,
  },
  {
    path: ACCOUNT_URL.MAIN,
    component: <Account />,
    isMember: true,
  },
  {
    path: ROUTE_URL.NOTIFICATIONS,
    component: <Notifications />,
    isMember: true,
  },
  {
    path: SETTING_URL.MAIN,
    component: <Settings />,
    isMember: true,
  },
  {
    path: SERVICE_URL.MAIN,
    component: <Services />,
    isMember: true,
  },
  {
    path: '/*',
    component: <Servers />,
    isMember: true,
  },
];

export default { general, withHeader };
