import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { onError } from 'apollo-link-error';

import { AuthService } from '../services';

const token = AuthService.getTokenAuth();

const customFetch = (uri, options) => {
  return fetch(uri, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const logoutLink = onError(({ networkError }) => {
  if (networkError.statusCode === 401) AuthService.logout();
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
  fetch: customFetch,
});

const client = new ApolloClient({
  link: ApolloLink.from([logoutLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

export default client;
