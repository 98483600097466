import { urlBuilder } from '../../utils/route';

const account = {
  MAIN: '/account/*',
  PROFILE: '/profile',
  PASSWORD: '/password',
};

export default account;

export const accountURL = () => {
  return new Proxy(account, urlBuilder('/account'));
};
