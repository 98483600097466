import BaseService from '../baseServices';
import REST from '../../configs/rest';

function getListProvinces(params = {}) {
  const query = {
    offset: 0,
    limit: 10000,
    where: {
      name: {
        isubstring: params?.keyword,
      },
    },
  };
  if (!Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    delete query.where;
  }
  if (Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    if (params.keyword === '' || params.keyword === null) {
      delete query.where;
    }
  }

  return BaseService.get(REST.PROVINCES, {
    params: {
      type: 'offset',
      query,
    },
  });
}

function getListDistricts(params = {}) {
  const query = {
    offset: 0,
    limit: 10000,
    where: {
      // and: [
      // {
      // name: {
      //   isubstring: params?.keyword,
      // },
      // },
      // {
      province_code: params?.provinceCode ?? '',
      // },
      // ],
    },
  };

  // if (!Object.prototype.hasOwnProperty.call(params, 'keyword')) {
  //   query.where.and.splice(0, 1);
  // }
  // if (Object.prototype.hasOwnProperty.call(params, 'keyword')) {
  //   if (params?.keyword === '' || params?.keyword === null) {
  //     query.where.and.splice(0, 1);
  //   }
  // }
  // if (!Object.prototype.hasOwnProperty.call(params, 'provinceCode')) {
  //   query.where.and.pop();
  // }
  // if (Object.prototype.hasOwnProperty.call(params, 'provinceCode')) {
  //   if (params?.provinceCode === '' || params?.provinceCode === null) {
  //     query.where.and.pop();
  //   }
  // }

  return BaseService.get(REST.DISTRICTS, {
    params: {
      type: 'offset',
      query,
    },
  });
}

function getListSubdistricts(params = {}) {
  const query = {
    offset: 0,
    limit: 100,
    where: {
      and: [
        {
          name: {
            isubstring: params?.keyword,
          },
        },
        {
          district_code: params?.districtCode ?? '',
        },
      ],
    },
  };

  if (!Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    query.where.and.splice(0, 1);
  }
  if (Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    if (params?.keyword === '' || params?.keyword === null) {
      query.where.and.splice(0, 1);
    }
  }
  if (!Object.prototype.hasOwnProperty.call(params, 'districtCode')) {
    query.where.and.pop();
  }
  if (Object.prototype.hasOwnProperty.call(params, 'districtCode')) {
    if (params?.districtCode === '' || params?.districtCode === null) {
      query.where.and.pop();
    }
  }

  return BaseService.get(REST.SUBDISTRICTS, {
    params: {
      type: 'offset',
      query,
    },
  });
}

function getListVillages(params = {}) {
  const query = {
    offset: 0,
    limit: 100,
    where: {
      and: [
        {
          name: {
            isubstring: params?.keyword,
          },
        },
        {
          subdistrict_code: params?.subdistrictCode ?? '',
        },
      ],
    },
  };

  if (!Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    query.where.and.splice(0, 1);
  }
  if (Object.prototype.hasOwnProperty.call(params, 'keyword')) {
    if (params?.keyword === '' || params?.keyword === null) {
      query.where.and.splice(0, 1);
    }
  }
  if (!Object.prototype.hasOwnProperty.call(params, 'subdistrictCode')) {
    query.where.and.pop();
  }
  if (Object.prototype.hasOwnProperty.call(params, 'subdistrictCode')) {
    if (params?.subdistrictCode === '' || params?.subdistrictCode === null) {
      query.where.and.pop();
    }
  }

  return BaseService.get(REST.VILLAGES, {
    params: {
      type: 'offset',
      query,
    },
  });
}

export default {
  getListProvinces,
  getListDistricts,
  getListSubdistricts,
  getListVillages,
};
