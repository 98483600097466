import axios from 'axios';
import BaseService from '../baseServices';
import REST from '../../configs/rest';

const downloadReq = (body) => {
  return BaseService.post(REST.DOWNLOAD_REQUEST, body);
};

const uploadReq = (body) => {
  return BaseService.post(REST.UPLOAD_REQUEST, body);
};

const uploadFile = (url, body) => {
  return axios.put(url, body, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });
};

export default {
  downloadReq,
  uploadReq,
  uploadFile,
};
