import { urlBuilder } from '../../utils/route';

const auth = {
  MAIN: '/auth/*',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
  TEST: {
    MAIN: '/test/*',
    SUB: '/sub',
    SECOND: {
      MAIN: '/second/*',
      SUB_SECOND: '/sub-second',
      TEAM_ID: '/:teamId',
    },
    THIRD: {
      MAIN: '/group/*',
      GROUP_ID: {
        MAIN: '/:groupId',
        USERS: {
          MAIN: '/user/*',
          USER_ID: '/:userId',
        },
      },
    },
  },
};

export default auth;

export const authURL = () => {
  return new Proxy(auth, urlBuilder('/auth'));
};

// const a = urlParams(authURL().TEST.THIRD.GROUP_ID.USERS.USER_ID, {
//   groupId: '1234',
//   userId: 'andikaarif',
// });
// console.log(a, authURL().TEST.THIRD.GROUP_ID.USERS.USER_ID, authURL().LOGIN);
