import { urlBuilder } from '../../utils/route';

const server = {
  MAIN: '/servers/*',
  CREATE: '/create',
  SERVER_ID: {
    MAIN: '/:serverId/*',
    DASHBOARD: '/dashboard',
    INSTALLATION: '/installation',
    HEALTH: '/health',
    WEB_APP: {
      MAIN: '/web-applications/*',
      CREATE: '/create',
      WEB_APP_ID: {
        MAIN: '/:webAppId/*',
        SUMMARY: '/summary',
        DOMAIN_MANAGER: {
          MAIN: '/domain-manager/*',
          CREATE: '/create',
        },
        ACTIVITY_LOG: '/activity-log',
        SETTING: '/setting',
      },
    },
    DATABASE: '/database',
    SERVICES: '/services',
    FILE_MANAGER: '/file-manager',
    CRONJOB: {
      MAIN: '/cronjob/*',
      CREATE: '/create',
    },
    SSH: {
      MAIN: '/ssh/*',
      CREATE: '/create',
    },
    SECURITY: {
      MAIN: '/security/*',
      CREATE: '/create',
    },
    ACTIVITY_LOG: '/activity-log',
    SETTING: '/setting',
  },
};

export default server;

export const serverURL = () => {
  return new Proxy(server, urlBuilder('/servers'));
};

// const b = urlParams(serverURL().SERVER_ID.WEB_APP.WEB_APP_ID.SUMMARY, {
//   serverId: '1234',
//   webAppId: '23456',
// });
