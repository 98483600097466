import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import routes from './route';
import { AuthService } from '../services';
import { authURL } from '../configs/url/auth';
// import ReactGA from 'react-ga';

const PrivateRoute = ({ children }) => {
  if (!AuthService.isUserAuthenticated()) {
    return <Navigate to={authURL().LOGIN} />;
  }
  return children;
};

// {route.withHeader && <PageHeader />}
// {route.component}

const App = () => {
  return (
    <Suspense fallback={<Spin />}>
      <BrowserRouter>
        <Routes>
          {routes.general.map((route) => {
            if (route.isPublic) {
              return (
                <Route
                  path={route.path}
                  element={route.component}
                  key={route.path}
                />
              );
            }
            return (
              <Route
                key={route.path}
                element={<PrivateRoute>{route.component}</PrivateRoute>}
                path={route.path}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
