import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { IntercomProvider } from 'react-use-intercom';

// import 'antd/dist/antd.less';
import 'antd/dist/antd.min.css';
import AppWrapper from './App.style';
import Pages from './pages';
import client from './configs/apolloClients';
import './index.css';
// import {
//   onMessageListener,
//   initializeFirebase,
//   requestPermission,
// } from './configs/firebase';
// import { browserNotification } from './utils/notification';
// import { logoSmall } from './assets';

// import ReactGA from 'react-ga';

const App = () => {
  // ReactGA.initialize(process.env.REACT_APP_GA);
  // ReactGA.pageview(window.location.pathname + window.location.search);
  // requestPermission();
  // onMessageListener()
  //   .then((payload) => {
  //     const { title, subtitle } = payload.data;
  //     browserNotification(title, {
  //       time: 1000,
  //       body: subtitle,
  //       dir: 'auto',
  //       icon: logoSmall,
  //     });
  //   })
  //   .catch((err) => {
  //     return Promise.reject(err);
  //   });

  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

  return (
    <ApolloProvider client={client}>
      <HelmetProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <AppWrapper className="appWrapper">
            <Pages />
          </AppWrapper>
        </IntercomProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
};

export default App;
// initializeFirebase();
