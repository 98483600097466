import { urlBuilder } from '../../utils/route';

const setting = {
  MAIN: '/settings/*',
  GENERAL: '/general',
  BILLING: '/billing',
  PAYMENT_HISTORY: '/payment-history',
};

export default setting;

export const settingURL = () => {
  return new Proxy(setting, urlBuilder('/settings'));
};
