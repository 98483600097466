import { urlBuilder } from '../../utils/route';

const admin = {
  MAIN: '/admin/*',
  USERS: '/users',
  TEAMS: '/teams',
  LICENSES: '/licenses',
  PAYMENTS: '/payments',
  TRANSACTIONS: '/transactions',
};

export default admin;

export const adminURL = () => {
  return new Proxy(admin, urlBuilder('/admin'));
};
